import axios from "axios";
import authHeader from "./auth-header";
const API_URL = "https://premiumtutors.tailwindsnippets.net/";
//const API_URL = 'http://127.0.0.1:8000/'
class UserService {
  contactUs() {
    return axios.get(API_URL + "contact-us/");
  }
  getStats() {
    return axios.get(API_URL + "user/stats/");
  }
  changePassword(data) {
    return axios.post(API_URL + "auth/password/change/", data, {
      headers: authHeader(),
    });
  }
  getAuthUser() {
    return axios.get(API_URL + "auth/user/", { headers: authHeader() });
  }
  does_account_exist() {
    return axios.get(API_URL + "does_account_exist/");
  }
  createProfile(data) {
    return axios.post(API_URL + "profiles/", data, { headers: authHeader() });
  }
  getSubjects() {
    return axios.get(API_URL + "subjects/", { headers: authHeader() });
  }
  getProfile(id) {
    return axios.get(API_URL + "profiles/" + id, { headers: authHeader() });
  }
  updateProfile(id, data) {
    return axios.put(API_URL + "profiles/" + id + "/", data, {
      headers: authHeader(),
    });
  }
  getTutorApplications(id) {
    return axios.get(API_URL + "tutor-applications/" + id, {
      headers: authHeader(),
    });
  }
  getAllTutorApplications() {
    return axios.get(API_URL + "tutor-applications/", {
      headers: authHeader(),
    });
  }

  getStudentApplications(id) {
    return axios.get(API_URL + "student-applications/" + id, {
      headers: authHeader(),
    });
  }
  getAllStudentApplications() {
    return axios.get(API_URL + "student-applications/", {
      headers: authHeader(),
    });
  }
  getAllUserProfiles() {
    return axios.get(API_URL + "profiles/", { headers: authHeader() });
  }
  createAplication(data) {
    return axios.post(API_URL + "student-applications/", data, {
      headers: authHeader(),
    });
  }
  createTutorAplication(data) {
    return axios.post(API_URL + "tutor-applications/", data, {
      headers: authHeader(),
    });
  }
  passwordResetRequest(data) {
    return axios.post(API_URL + "user/user-password-reset/", data, {
      headers: authHeader(),
    });
  }
  passwordResetConfirmation(data) {
    return axios.post(API_URL + "user/password-reset/confirm/", data, {
      headers: authHeader(),
    });
  }
  deleteUser(id) {
    return axios.delete(API_URL + "users/" + id, { headers: authHeader() });
  }
}
export default new UserService();
