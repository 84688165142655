import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'



Vue.use(VueRouter)

const routes = [


  {
    path: '/admin',
    name: 'Admin',
    //component: () => import(/* webpackChunkName: "Admin" */'../views/admin/Admin.vue'),
    component: () => import(/* webpackChunkName: "AdminHome" */'../views/admin/Dashboard.vue'),

    children: [
      //{
      //  path: 'home',
      //  name: 'AdminHome',
      //  component: () => import(/* webpackChunkName: "AdminHome" */'../views/admin/Dashboard.vue'),
      //},

      {
        path: 'tutors',
        name: 'AllTutors',
        component: () => import(/* webpackChunkName: "AllTutors" */'../views/admin/Tutors.vue'),
      },
      {
        path: 'students',
        name: 'AllStudents',
        component: () => import(/* webpackChunkName: "AllStudents" */'../views/admin/Students.vue')
      },
      {
        path: 'tutor-applications',
        name: 'TutorApplications',
        component: () => import(/* webpackChunkName: "TutorApplications" */'../views/admin/TutorApplications.vue')
      },
      {
        path: 'student-applications',
        name: 'StudentApplications',
        component: () => import(/* webpackChunkName: "StudentApplications" */'../views/admin/StudentApplications.vue')
      },

    ],
    beforeEnter: async (to, from, next) => {
      if (store.state.auth.user) {
        //await store.dispatch('setProfile')
        //const user = store.state.authProfile
       // if (user.user_type == "Admin") {
        //  console.log("I am admin")
          next()
       // }
      //  else {
      //    console.log("I am the 404")
       //   next({ name: 'Profile' })
        //}
//
      }
      else {
        console.log("I am the Login")
        next({ name: 'Login' })
      }
      //return true
    },

  },

  {
    path: '/admin/profile/:id',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */'../views/admin/Profile.vue'),
    props: true,
    beforeEnter: async (to, from, next) => {
      if (store.state.auth.user) {next()}
      else next({ name: 'Login' })
      //return true
    },
  
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Reset" */'../views/Privacy.vue')
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */'../views/auth/Login.vue'),
    
  
  },
  {
    path: '/auth/change-password/:id/:code',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "ChangePassword" */'../views/auth/PasswordChange.vue'),
    props: true
  },
  //{
   // path: '/home',
   // name: 'HomeRevamp',
   // component: () => import(/* webpackChunkName: "HomeRevamp" */'../views/HomeRevamp.vue')
  //}

  {
    path: '/auth/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "Reset" */'../views/auth/PasswordReset.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */'../views/Contact.vue')
  },
  {
    path: '/be-a-tutor',
    name: 'Tutor',
    component: () => import(/* webpackChunkName: "Tutor" */'../views/Tutor.vue')
  },

  {
    path: '/be-a-student',
    name: 'Student',
    component: () => import(/* webpackChunkName: "Student" */'../views/Student.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */'../views/Home.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */'../views/error-pages/404.vue')
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
