import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module';
import dataModule from '@/authentication/data.service.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth
    },
    state: {
        sideBarOpen: false,
        authProfile: null
    },
    getters: {
        sideBarOpen: state => {
            return state.sideBarOpen
        }
    },
    mutations: {
        toggleSidebar(state) {
            state.sideBarOpen = !state.sideBarOpen
        },
        profileData(state, profile) {
            state.authProfile = profile
        }
    },
    actions: {
        toggleSidebar(context) {
            context.commit('toggleSidebar')
        },
        async setProfile({ commit }) {

            await dataModule.getAuthUser().then(
                async (response) => {
                    await dataModule.getProfile(response.data.pk).then(
                        (response) => {
                            commit('profileData', response.data)
                        }
                    );
        
                }
            );



        }
    }
})
